import _ from 'lodash';
import { ChangeEvent, ChangeEventHandler } from 'react';
import { FormCheck, FormControl } from 'react-bootstrap';
import { DynamicDropdown } from 'Components/molecules';
import { FormSettings } from 'Core-utils/types';
import { DynamicFieldT } from 'Components/organisms/Integrations/Integrations';

type DynamicFieldProps = DynamicFieldT & {
    formId: string;
    selectedOptions: FormSettings;
    onOptionSelected: (formId: string, name: string) => ChangeEventHandler<HTMLInputElement>;
    onOptionChecked: (formId: string, name: string) => ChangeEventHandler<HTMLInputElement>;
    hasPlaceholder?: boolean;
};

const DynamicField = ({
    formId,
    formProps,
    name,
    options,
    type,
    selectedOptions,
    onOptionSelected,
    onOptionChecked,
    hasPlaceholder = false,
}: DynamicFieldProps) => {
    switch (type) {
        case 'dropdown': {
            return (
                <DynamicDropdown
                    variant="default"
                    options={options as string[]}
                    selectedOptions={selectedOptions?.[formId!] ? (selectedOptions?.[formId!]?.[name] as string) : ''}
                    onOptionSelected={(option: string) =>
                        onOptionSelected(formId!, name)({ target: { value: option } } as ChangeEvent<HTMLInputElement>)
                    }
                />
            );
        }
        case 'checkbox':
        case 'radio': {
            return (
                <FormCheck
                    type={type}
                    onChange={onOptionChecked(formId!, name)}
                    checked={selectedOptions?.[formId!]?.[name] as boolean}
                />
            );
        }
        case 'switch': {
            return (
                <FormCheck
                    type={type}
                    onChange={onOptionChecked(formId!, name)}
                    checked={selectedOptions?.[formId!]?.[name] as boolean}
                />
            );
        }
        default: {
            return (
                <FormControl
                    className="text-white bg-transparent mb-2"
                    onInput={onOptionSelected(formId!, name)}
                    defaultValue={selectedOptions?.[formId]?.[name]}
                    {...formProps}
                    placeholder={hasPlaceholder ? '********' : ''}
                >
                    {options?.map((option: any) => (
                        <option key={option?.key} value={option?.value} />
                    ))}
                </FormControl>
            );
        }
    }
};

export default DynamicField;
