import { useContext, useEffect, useState } from 'react';
import { IntegrationHeader } from 'Components/organisms';
import { BackButton } from 'Components/molecules';
import { useNavigate, useParams } from 'react-router-dom';
import { POLICIES_ROUTE } from 'Core-utils/routes';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';
import convertToDynamicTabsToTabs from '../../organisms/AddIntegrationModal/ConvertDynamicTabsToTabs';
import {
    ALLOWED_TABS,
    CONNECTOR_SETTINGS_SAVED,
    CREDENTIALS_SAVED,
    ERROR_MESSAGE,
    ERROR_VARIANT,
    OAUTH_SETTINGS_SAVED,
    REMOVE_INTEGRATION,
    REMOVE_INTEGRATION_CONTENT,
    REMOVE_TOAST_MESSAGE,
    SUCCESS_VARIANT,
    YES_DELETE_IT,
} from 'Core-utils/constants/constants';
import Accordion, { AccordionItemProps } from 'Components/atoms/Accordion/Accordion';
import { DynamicTab } from 'Components/organisms/Integrations/Integrations';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import {
    AMP_SETTINGS,
    AmpSettingUpsertDto,
    ConnectorStatus,
    ConnectorUpsertDto,
    CredentialStatus,
    KIND,
} from '@ampsec/platform-client';
import { getInstalledConnectors, removeSetting, updateConnectorStatus } from 'Apis/library';
import { useApiData } from 'Hooks/useApiData';
import { InstalledVendorProps, FormSettings, FormValue, FormSetting } from 'Core-utils/types';
import { extractInfoFromConnectors } from 'Core-utils/helpers/helpers';
import { RootState } from 'Rdx/store';
import { useSelector } from 'react-redux';
import { Chip, Typography } from 'Components/atoms';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { getApi } from 'Src/Api';
import { RemoveIntegration } from 'Components/molecules';
import { ConfirmationModal } from 'Components/molecules';
import _ from 'lodash';
import './styles.scss';
import useTimeSpent from 'Hooks/useTimeSpent';

const COMPONENT_IDENTIFIER = 'Edit Connector Page';

const VendorDetailPage = ({ installedConnectors }: { installedConnectors: ConnectorUpsertDto[] }) => {
    const navigate = useNavigate();
    const { cid } = useParams();
    useTimeSpent(COMPONENT_IDENTIFIER, { cid });
    const providers = useSelector((state: RootState) => state?.providerMap);
    const [extractedData, setExtractedData] = useState<InstalledVendorProps>({} as InstalledVendorProps);
    const [dynamicTabs, setDynamicTabs] = useState<DynamicTab[]>([]);
    const [integrationTitle, setIntegrationTitle] = useState<string>(extractedData?.title);
    const {
        contentfulData: { integrations, providerNameIntegrationMap },
    } = useContext(ContentfulContext);
    const [contentfulConfiguration, setContentfulConfiguration] = useState<any>({});
    const [connectorStatus, setConnectorStatus] = useState<ConnectorStatus>(ConnectorStatus.DISABLED);
    const [checked, setChecked] = useState<boolean>(false);
    const [accordionItems, setAccordionItems] = useState<AccordionItemProps[]>([]);
    const authModel = useSelector((state: RootState) => state?.auth);
    const { addToast } = useContext(ToastsContext);
    const [show, setShow] = useState<boolean>(false);
    const [connectorSettings, setConnectorSettings] = useState<{
        [id: string]: { id: string; key: string; value: FormValue };
    }>({});
    const [selectedOptions, setSelectedOptions] = useState<FormSettings>({});

    useEffect(() => {
        if (!integrations || !extractedData?.title) {
            return;
        }
        setContentfulConfiguration(integrations[extractedData?.title]);
    }, [integrations, extractedData?.title]);

    useEffect(() => {
        if (cid === null || cid === undefined) {
            return;
        }
        const getConnectorSettings = async () => {
            const api = await getApi();
            const settingsMap = await api?.settings.getSettingsMap();
            const rawSettingsConnectorMap = settingsMap?.getRawMap()?.connectorSettings?.[cid];
            const tenantSettings = settingsMap?.getRawMap()?.tenantSettings;

            if (rawSettingsConnectorMap) {
                const newConnectorSettings: any = {};
                Object.keys(rawSettingsConnectorMap as any).forEach((key) => {
                    const rawSetting = rawSettingsConnectorMap?.[key] as any;
                    newConnectorSettings[key] = {
                        id: rawSetting.id,
                        key: rawSetting.key,
                        value: rawSetting.value,
                    };
                });
                Object.keys(tenantSettings as any).forEach((key) => {
                    const rawSetting = tenantSettings?.[key] as any;
                    newConnectorSettings[key] = {
                        id: rawSetting.id,
                        key: rawSetting.key,
                        value: typeof rawSetting.value === 'object' ? rawSetting.value?.value : rawSetting.value,
                    };
                });
                setConnectorSettings(newConnectorSettings);
            }
        };

        getConnectorSettings();
    }, [cid]);

    useEffect(() => {
        if (!contentfulConfiguration?.dynamicTabs?.tabs || !connectorSettings) {
            return;
        }

        const forms = contentfulConfiguration?.dynamicTabs?.tabs?.filter((tab: any) => tab?.type === 'form');

        const currentOptions: FormSettings = {};
        forms?.forEach((form: any) => {
            currentOptions[form?.formId] = {};
            // eslint-disable-next-line max-nested-callbacks
            form?.fields?.forEach((field: any) => {
                currentOptions[form?.formId][field?.name] =
                    connectorSettings?.[field?.name]?.value || field?.defaultValue;
            });
        });
        setSelectedOptions(currentOptions);
    }, [connectorSettings, contentfulConfiguration]);

    const handleStatusChange = (newStatus: ConnectorStatus.ACTIVE | ConnectorStatus.DISABLED) => {
        if (cid === null || cid === undefined) {
            return;
        } else {
            updateConnectorStatus(cid, newStatus).then((response) => {
                if (response?.error) {
                    addToast({
                        severity: ERROR_VARIANT,
                        body: ERROR_MESSAGE,
                    });
                } else {
                    setConnectorStatus(newStatus);
                    setChecked((checked) => !checked);
                }
            });
        }
    };

    const handleStatusToggle = () => {
        if (
            connectorStatus === ConnectorStatus.ACTIVE ||
            connectorStatus === ConnectorStatus.DEGRADED ||
            connectorStatus === ConnectorStatus.SYNCING
        ) {
            handleStatusChange(ConnectorStatus.DISABLED);
        } else {
            handleStatusChange(ConnectorStatus.ACTIVE);
        }
    };

    const handleSelectOption = (formId: string, fieldName: string, value: string | string[] | boolean) => {
        setSelectedOptions((prevOptions: any) => ({
            ...prevOptions,
            [formId]: {
                ...prevOptions[formId],
                [fieldName]: value,
            },
        }));
    };

    const splitSettings = (
        settings: FormSetting,
    ): [
        newSettings: {
            id: string;
            key: string;
            cid?: string;
            value: FormValue;
        }[],
        existingSettings: {
            id: string;
            key: string;
            cid: string;
            value: FormValue;
        }[],
    ] => {
        if (!cid) {
            return [[], []];
        }
        const currentSettingKeys = Object.keys(settings);
        // These settings need to hit the PATCH endpoint (update)
        const existingSettings = Object.keys(connectorSettings)
            .filter((key) => currentSettingKeys.includes(key) && settings[key] !== connectorSettings[key].value)
            .map((key) => ({
                id: connectorSettings?.[key]?.id,
                key: key,
                cid: cid,
                value: settings?.[key],
            }));
        // These settings need to hit the PUT endpoint (create)
        const newSettings: { id: string; key: string; value: string | string[] | boolean }[] = [];
        Object.keys(settings)
            .filter(
                (key) =>
                    settings[key] &&
                    settings[key] !== null &&
                    settings[key] !== undefined &&
                    settings[key] !== connectorSettings?.[key]?.value,
            )
            .forEach((key) => {
                if (existingSettings.filter((setting) => setting.id === connectorSettings?.[key]?.id).length === 0) {
                    settings?.[key] &&
                        newSettings.push({
                            id: connectorSettings?.[key]?.id || '',
                            key: key,
                            value: settings?.[key] as FormValue,
                        });
                }
            });

        return [newSettings, existingSettings];
    };

    const handleButtonClick = (formId: string, strategy?: string) => async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.preventDefault();
        const api = await getApi();

        if (strategy === 'connectorSettings' || strategy === 'tenantSettings' || strategy === 'slackBot') {
            try {
                if (strategy === 'slackBot' && selectedOptions?.[formId]?.makeDefault) {
                    const settingsMap = await api?.settings.getSettingsMap();
                    const existingSetting = _.get(
                        (settingsMap as any)?.settings?.tenantSettings,
                        AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id,
                    );
                    const tenantSettings = settingsMap?.getRawMap()?.tenantSettings;

                    const existingSettingId = (tenantSettings as any)?.[
                        AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id
                    ].id as string;

                    if (existingSetting) {
                        const res = await api?.settings.update(existingSettingId, {
                            cid: cid,
                            key: AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id,
                            value: {
                                ...existingSetting,
                                cid: cid,
                            },
                        } as AmpSettingUpsertDto);

                        if (!res) {
                            throw new Error(`Unable to update existing settings for connector ${cid}`);
                        } else {
                            addToast({
                                severity: SUCCESS_VARIANT,
                                body: CONNECTOR_SETTINGS_SAVED,
                            });
                        }
                    } else {
                        const res = await api?.settings.create(
                            AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id,
                            {
                                cid: cid,
                            },
                        );

                        if (!res) {
                            throw new Error(`Unable to create new settings for connector ${cid}`);
                        } else {
                            addToast({
                                severity: SUCCESS_VARIANT,
                                body: CONNECTOR_SETTINGS_SAVED,
                            });
                        }
                    }

                    return;
                }
                const settingsToSplit =
                    strategy === 'slackBot'
                        ? _.pick(selectedOptions?.[formId], [AMP_SETTINGS.slack.SLACK_TEAM_ID.id])
                        : selectedOptions?.[formId];
                const [newSettings, existingSettings] = splitSettings(settingsToSplit);

                if (existingSettings.length > 0) {
                    const existingSettingsResponse = await api?.settings?.updateAll(existingSettings);

                    if (!existingSettingsResponse) {
                        throw new Error(`Unable to update existing settings for connector ${cid}`);
                    }
                }

                if (newSettings.length > 0) {
                    const newSettingsResponse = await api?.settings.createMultiple(newSettings, cid);

                    if (!newSettingsResponse) {
                        throw new Error(`Unable to create new settings for connector ${cid}`);
                    }
                }

                if (strategy === 'slackBot') {
                    const qaChannelFormValue = selectedOptions?.[formId]?.[AMP_SETTINGS.tenant.SLACK_QA_CHANNEL_ID.id];
                    const shouldSetQaChannelId = qaChannelFormValue && qaChannelFormValue !== '';

                    if (shouldSetQaChannelId) {
                        const existingQaChannelSetting = connectorSettings[AMP_SETTINGS.tenant.SLACK_QA_CHANNEL_ID.id];
                        if (existingQaChannelSetting) {
                            const exisitinQaChannelSettingResponse = await api?.settings.update(
                                existingQaChannelSetting.id,
                                {
                                    key: AMP_SETTINGS.tenant.SLACK_QA_CHANNEL_ID.id,
                                    value: qaChannelFormValue,
                                } as AmpSettingUpsertDto,
                            );

                            // eslint-disable-next-line max-depth
                            if (!exisitinQaChannelSettingResponse) {
                                throw new Error(`Unable to update existing settings for connector ${cid}`);
                            }
                        } else {
                            const createQaChannelResponse = await api?.settings.create(
                                AMP_SETTINGS.tenant.SLACK_QA_CHANNEL_ID.id,
                                qaChannelFormValue,
                            );

                            // eslint-disable-next-line max-depth
                            if (!createQaChannelResponse) {
                                throw new Error('Unable to create Engagement Channel');
                            } else {
                                addToast({
                                    severity: SUCCESS_VARIANT,
                                    body: CONNECTOR_SETTINGS_SAVED,
                                });
                            }
                        }
                    }
                }
            } catch (err) {
                addToast({
                    severity: ERROR_VARIANT,
                    body: ERROR_MESSAGE,
                });
            }
        } else if (strategy === 'credentials') {
            const res = await api?.install.credentials(cid!, selectedOptions[formId]);
            if (!res) {
                addToast({
                    severity: ERROR_VARIANT,
                    body: ERROR_MESSAGE,
                });
                return;
            }

            addToast({
                severity: SUCCESS_VARIANT,
                body: CREDENTIALS_SAVED,
            });
        } else if (strategy === 'oauth') {
            const clientRedirectUrl = `${window.location.origin}/policies/${cid}`;
            const oauthInstallUrl = await api?.install.getOauthInstallUrl(
                cid!,
                integrationTitle.replace(/^[-\s]+|[-\s]+$/g, '').toLowerCase(),
                clientRedirectUrl,
            );
            if (!oauthInstallUrl) {
                addToast({
                    severity: ERROR_VARIANT,
                    body: ERROR_MESSAGE,
                });
            } else {
                window.location.href = oauthInstallUrl;
            }

            addToast({
                severity: SUCCESS_VARIANT,
                body: OAUTH_SETTINGS_SAVED,
            });
        }
    };

    const handleRemoveClick = () => setShow(true);

    const handleDeleteAction = () => {
        if (cid === null || cid === undefined) {
            return;
        } else {
            removeSetting(cid)
                .then(() => {
                    addToast({
                        severity: SUCCESS_VARIANT,
                        body: REMOVE_TOAST_MESSAGE,
                    });
                    navigate(POLICIES_ROUTE);
                })
                .catch(() => {
                    addToast({
                        severity: ERROR_VARIANT,
                        body: ERROR_MESSAGE,
                    });
                });
        }
    };

    const handleCloseModal = () => {
        setShow(false);
    };

    useEffect(() => {
        if (dynamicTabs?.length === 0 || cid === '' || cid === undefined) {
            return;
        }
        const tabs: any[] = convertToDynamicTabsToTabs(
            dynamicTabs,
            contentfulConfiguration!,
            selectedOptions,
            handleSelectOption,
            handleButtonClick,
            extractedData?.credentialStatus === CredentialStatus.VALID ||
                extractedData?.credentialStatus === CredentialStatus.INVALID,
        );

        const initialAccordionItems: AccordionItemProps[] = tabs?.map((tab, index) => ({
            key: `${index}-${tab.title}}`,
            children:
                tab.title === 'INSTALLATION GUIDE' ? (
                    <Typography variant="body2" color="text-medium-emphasis">
                        {tab.content}
                    </Typography>
                ) : (
                    tab.content
                ),
            title:
                tab.title === 'CREDENTIALS SETUP' || tab.title === 'INSTALLATION SETTINGS' ? (
                    <div className="d-flex gap-4">
                        <Typography variant="subtitle7" className="m-0">
                            {tab.title}
                        </Typography>
                        <Chip value={extractedData?.credentialStatus} variant={'resolved_issue_variant'} />
                    </div>
                ) : (
                    tab.title
                ),
            eventKey: index.toString(),
            isConfigurationRequired: false,
        }));

        setAccordionItems(initialAccordionItems);

        setAccordionItems((prevItems) => [
            ...(prevItems || []),
            {
                key: `${prevItems ? prevItems.length : 0}-${REMOVE_INTEGRATION}`,
                title: REMOVE_INTEGRATION,
                isConfigurationRequired: false,
                eventKey: prevItems ? prevItems.length.toString() : '0',
                children: <RemoveIntegration handleClick={handleRemoveClick} />,
            },
        ]);
    }, [contentfulConfiguration, dynamicTabs, cid, selectedOptions, providers, authModel, addToast]);

    useEffect(() => {
        if (installedConnectors.length > 0) {
            const data = extractInfoFromConnectors(installedConnectors, providers, providerNameIntegrationMap).find(
                (item) => item.cid === cid,
            );
            setExtractedData(data as InstalledVendorProps);
            const filteredIntegration = providerNameIntegrationMap?.[data?.provider.displayValue!];
            setIntegrationTitle(`${filteredIntegration?.name}`);
        }
        setConnectorStatus(extractedData?.status);
        if (contentfulConfiguration) {
            const filteredTabs = contentfulConfiguration?.dynamicTabs?.tabs?.filter(
                (tab: DynamicTab) => !ALLOWED_TABS.includes(tab?.contentfulKey ?? ''),
            );
            setDynamicTabs(filteredTabs);
        }
    }, [contentfulConfiguration, installedConnectors, integrations, providerNameIntegrationMap]);

    useEffect(() => {
        if (
            connectorStatus === ConnectorStatus.ACTIVE ||
            connectorStatus === ConnectorStatus.DEGRADED ||
            connectorStatus === ConnectorStatus.SYNCING
        ) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [connectorStatus]);

    return (
        <div className="vendor-details-page">
            <BackButton navigateBack={POLICIES_ROUTE} />
            <div className="vendor-details-page__content d-flex flex-column bg-text-black mt-2 gap-3">
                <IntegrationHeader
                    icon={extractedData?.imageUrl}
                    label={integrationTitle}
                    updatedTime={extractedData?.lastRunTime ?? ''}
                    status={connectorStatus}
                    checked={checked}
                    onToggle={handleStatusToggle}
                    syncTime={extractedData?.syncTime ?? ''}
                />
                <Accordion accordionItems={accordionItems} alwaysOpen={true} defaultActiveKey={['0']} />
                <ConfirmationModal
                    modalTitle={REMOVE_INTEGRATION}
                    modalBodyTitle={REMOVE_INTEGRATION_CONTENT}
                    onPrimaryButtonClick={handleDeleteAction}
                    primaryButtonLabel={YES_DELETE_IT}
                    show={show}
                    onClose={handleCloseModal}
                    buttonVariant="danger"
                />
            </div>
        </div>
    );
};
const VendorDetails = () => {
    const { data: installedConnectors, isLoading, error, refresh } = useApiData<ConnectorUpsertDto, ConnectorUpsertDto>(
        KIND.CONNECTORS,
        () => getInstalledConnectors({ limit: 100 }),
        (a) => a,
    );
    const renderVendorDetailPage = () =>
        installedConnectors ? (
            <VendorDetailPage installedConnectors={installedConnectors as ConnectorUpsertDto[]} />
        ) : null;
    return (
        <PageErrorHandlerAndLoaderWrapper
            error={error}
            render={renderVendorDetailPage}
            isLoading={isLoading}
            handleRefresh={refresh}
        />
    );
};

export default VendorDetails;
