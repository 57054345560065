import persistPlugin from '@rematch/persist';
import { RematchDispatch, RematchRootState, init } from '@rematch/core';
import storage from 'redux-persist/lib/storage';
import { models } from './models';
import type { RootModel } from './models';
import { cacheTenantData, initializeApi } from 'Src/Api';
import { SIGN_IN } from 'Core-utils/routes';
import { redirect } from 'react-router-dom';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['orgModel'],
};

function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

async function persistRyhydrationCallbackHandler() {
    const token = store?.getState()?.auth?.token;

    if (!token) {
        return;
    }
    const api = await initializeApi(token);
    try {
        await api?.identity?.me();

        const jwt = parseJwt(token);
        const nowS = Math.trunc(Date.now() / 1000);
        // limiting to max of 24 hours since that is max of token expiration
        // will likely be changed later. Addressed because this was causing
        // issue in dev where I use 30 day tokens and it was overflowing
        // the `setTimeout` call causing immediate execution.
        const TWELVE_HOURS = 24 * 60 * 60 * 1000;
        const timeout = Math.min(TWELVE_HOURS, Math.floor((jwt.exp - nowS) * 1000));

        setTimeout(() => {
            store.dispatch.auth.setAuthToken(null);
            redirect(SIGN_IN);
        }, timeout);

        await cacheTenantData();
    } catch (error: any) {
        store.dispatch.auth.setAuthToken(null);
        window.location.replace(SIGN_IN);
    }
}

export const store = init<RootModel>({
    models: models,
    plugins: [persistPlugin(persistConfig, undefined, undefined, persistRyhydrationCallbackHandler)],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
