import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import classNames from 'classnames';
import { Typography } from 'Components/atoms';
import { AND, ENGAGEMENT_EDIT, MORE } from 'Core-utils/constants/constants';
import { AllowedExpandableEngagementColors, AutoCompleteItem } from 'Core-utils/types';
import './styles.scss';

interface ExpandableMenuCardProps {
    header: string;
    caption: (AutoCompleteItem | string)[];
    defaultCaption?: string;
    children: JSX.Element;
    color: AllowedExpandableEngagementColors;
    onSave?: (caption: (AutoCompleteItem | string)[]) => void;
}

const ExpandableMenuCard = ({ header, caption, children, color, onSave, defaultCaption }: ExpandableMenuCardProps) => {
    const accordionRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const filteredCaption = caption.filter((item) => item !== null && item !== undefined);

    const toggleEditingState = () => {
        setIsOpen((isOpen) => !isOpen);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (accordionRef.current && !(accordionRef.current as HTMLDivElement).contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
            caption.length > 0 && onSave && onSave(caption);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    const containerClasses = classNames('expandable-engagement w-100 position-relative', { open: isOpen });
    const headerClasses = classNames(`expandable-engagement__accordion-header bg-${color}`, { editing: !isOpen });

    const buttonText = !isOpen && ENGAGEMENT_EDIT;

    const renderDefaultCaption = () => (
        <Typography variant={'body5'} color="text-medium-emphasis" style={{ marginTop: '0.5rem' }}>
            {defaultCaption}
        </Typography>
    );

    const renderCaptionItem = (item: AutoCompleteItem, index: number, arrayLength: number) => {
        const providerIcon =
            typeof item?.icon === 'string' ? <Image src={item.icon} width={15} height={17} /> : item?.icon;
        return (
            <div key={index} className="d-flex gap-1 justify-content-center align-items-center mt-2">
                {item?.icon && providerIcon}
                <Typography variant="body5">{item.value}</Typography>
                {index < arrayLength - 1 && <span>,</span>}
            </div>
        );
    };

    const filteredCaptionItems = filteredCaption as AutoCompleteItem[];

    const renderCaption = () => (
        <>
            {filteredCaptionItems.length === 0 && renderDefaultCaption()}
            <div className="d-flex justify-content-between align-items-center gap-1 parent-container">
                {filteredCaptionItems
                    .slice(0, 4)
                    .map((item, index) => renderCaptionItem(item, index, filteredCaptionItems.length))}
                {filteredCaptionItems.length > 4 && (
                    <Typography variant="body5" style={{ marginTop: '0.5rem' }}>
                        {`${AND} ${filteredCaptionItems.length - 4} ${MORE}`}
                    </Typography>
                )}
            </div>
        </>
    );

    return (
        <div className={containerClasses} data-testid="expandable-engagement" ref={accordionRef}>
            <div className={headerClasses}>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column align-items-start">
                        <Typography variant="h6">{header}</Typography>
                        {!isOpen && renderCaption()}
                    </div>
                    <Typography variant="body1">
                        <u onClick={toggleEditingState}>{buttonText}</u>
                    </Typography>
                </div>
            </div>
            <div className={`expandable-engagement__accordion-content bg-${color}`}>{isOpen && children}</div>
            <div className={`expandable-engagement__bulge ${color}`} />
        </div>
    );
};
export default ExpandableMenuCard;
