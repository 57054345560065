import { ReactSVG } from 'react-svg';
import { Button } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from 'Components/atoms';
import {
    DASHBOARD_SIGN_IN,
    GET_IN_TOUCH,
    SIGN_IN_ERROR_TEXT,
    SignInButtonsMaps,
    SignInInfoText,
    TERMS_OF_USE,
    PRIVACY_POLICY,
    TERMS_OF_USE_LINK,
    PRIVACY_POLICY_LINK,
} from 'Core-utils/constants/constants';
import AmplifierSignInLogo from 'Assets/icons/AmplifierSignInLogo.svg';
import { useMemo } from 'react';
import { ORG_DASHBOARD_ROUTE } from 'Core-utils/routes';
import { RootState, store } from 'Rdx/store';
import './styles.scss';

type SsoError = 'Google' | 'Microsoft 365' | 'Okta' | null;

interface SignInProps {
    ssoError: SsoError;
    setSsoOption: (_key: string | null) => void;
}

interface SignInButtonsComponentProps {
    clickHandler: (_id: string) => void;
}

const SignInErrorComponent = ({ error }: { error: string | null }) => (
    <div className="d-flex gap-1">
        <Typography variant="body5" color="secondary-accent-red-4">
            {error} {SIGN_IN_ERROR_TEXT}
        </Typography>
    </div>
);

const SignInButtonsComponent = ({ clickHandler }: SignInButtonsComponentProps) => {
    const isError = store.getState().ssoModel.error ? true : false;
    const ssoOption = store.getState().ssoModel.ssoOption ?? '';
    const SignInButtons = useMemo(() => {
        return SignInButtonsMaps?.map(({ Icon, text, id }, idx) => {
            const errorVariant =
                isError && id === ssoOption
                    ? 'outline border border-secondary-accent-red-4 border-2'
                    : 'outline border border-primary-500 border-2';

            return (
                <div key={idx} className="d-flex flex-column gap-3 align-items-center">
                    <Button
                        variant={errorVariant}
                        className="text-text-high-emphasis d-flex align-items-center"
                        onClick={() => clickHandler(id)}
                    >
                        <div className="icon-wrapper">{Icon}</div>
                        <Typography variant="subtitle2">{text}</Typography>
                    </Button>
                </div>
            );
        });
    }, [isError]);

    return (
        <div className="sign-in-page__sign-in bg-structural-CardBG rounded-end text-text-high-emphasis d-flex flex-column text-center justify-content-center align-items-center gap-3">
            {isError && <SignInErrorComponent error={ssoOption} />}
            <Typography variant="subtitle1">{DASHBOARD_SIGN_IN}</Typography>
            {SignInButtons}
        </div>
    );
};

const SignInInfoComponent = () => (
    <div className="sign-in-page__get-in-touch bg-secondary-accent-blue-2 text-text-high-emphasis d-flex flex-column text-center justify-content-center rounded-start">
        <ReactSVG src={AmplifierSignInLogo} />
        <Typography variant="subtitle2">{SignInInfoText}</Typography>
        <Button
            href="mailto:info@amplifiersecurity.com"
            variant="link"
            className="text-text-high-emphasis text-decoration-none p-0"
        >
            <Typography variant="body1">{GET_IN_TOUCH}</Typography>
        </Button>
        <div className="d-flex gap-1 justify-content-center">
            <Link to={TERMS_OF_USE_LINK} target="_blank">
                <Typography variant="body5" color="text-high-emphasis">
                    {TERMS_OF_USE}
                </Typography>
            </Link>
            <Typography variant="body5">&</Typography>
            <Link to={PRIVACY_POLICY_LINK} target="_blank">
                <Typography variant="body5" color="text-high-emphasis">
                    {PRIVACY_POLICY}
                </Typography>
            </Link>
        </div>
    </div>
);

const SignIn = ({ setSsoOption }: SignInProps) => {
    const navigate = useNavigate();
    const auth = useSelector((state: RootState) => state.auth);
    if (auth.token) {
        window.location.replace(ORG_DASHBOARD_ROUTE);
    }

    const clickHandler = (option: string | null) => {
        setSsoOption(option);
        if (option === 'Google') {
            const redirectUrl = `${window.location.origin}/auth/redirect`;
            window.location.href = `${
                process.env.PLATFORM_API_URL
            }/api/v1/auth/google/login?redirectUrl=${encodeURIComponent(redirectUrl)}`;
        } else if (option === 'Entra') {
            const redirectUrl = `${window.location.origin}/auth/redirect`;
            window.location.href = `${
                process.env.PLATFORM_API_URL
            }/api/v1/auth/entra/login?redirectUrl=${encodeURIComponent(redirectUrl)}`;
        } else {
            navigate(ORG_DASHBOARD_ROUTE);
        }
    };

    return (
        <div className="sign-in-page-container d-flex align-items-center justify-content-center bg-structural-BG">
            <div className="sign-in-page d-flex">
                <SignInInfoComponent />
                <SignInButtonsComponent clickHandler={clickHandler} />
            </div>
        </div>
    );
};

const mapState = (state: { ssoModel: { ssoOption: any } }) => ({
    ssoOption: state.ssoModel.ssoOption,
});

const mapDispatch = (dispatch: any) => ({
    setSsoOption: (option: any) => dispatch.ssoModel.setSsoOption(option),
});

export default connect(mapState, mapDispatch)(SignIn);
