import { Container, Nav, Navbar, Dropdown, Image } from 'react-bootstrap';
import AmplifierLogoBlack from 'Assets/icons/Amplifier_Logo_Black.svg';
import { Typography } from 'Components/atoms';
import { ReactSVG } from 'react-svg';
import { FaUserCircle } from '@react-icons/all-files/fa/FaUserCircle';
import { FaKey } from '@react-icons/all-files/fa/FaKey';
import { MdOutlineKeyboardArrowDown } from '@react-icons/all-files/md/MdOutlineKeyboardArrowDown';
import SignOutIcon from 'Assets/icons/SignOutIcon.svg';
import { SIGN_IN } from 'Core-utils/routes';
import { Link, useNavigate } from 'react-router-dom';
import { adminNavItems, viewerNavItems } from 'Core-utils/constants/constants';
import { NavLink } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { Dispatch, RootState } from 'Rdx/store';
import { useDispatch, useSelector } from 'react-redux';
import { clearSWRCache } from 'Core-utils/helpers/helpers';
import { AuthRole } from '@ampsec/platform-client';
import './style.scss';

interface ProfileComponentProps {
    onClickSignOutHandler: () => void;
}

const UserAvatar = ({ userImage }: { userImage?: string }) => {
    if (userImage) {
        return <Image src={userImage} roundedCircle={true} className="sideNav__user-avatar-element  ms-1 me-1" />;
    }
    return <FaUserCircle className="sideNav__user-avatar-element ms-1 me-1" />;
};

const ProfileComponent = ({ onClickSignOutHandler }: ProfileComponentProps) => {
    const { user, tenants } = useSelector((state: RootState) => state.auth);
    const { pictureUrl, email } = user;

    return (
        <Dropdown className="border border-structural-stroke-300 rounded sign-out mb-3" drop="up" data-testid="profile">
            <Dropdown.Toggle className="text-text-high-emphasis">
                <UserAvatar userImage={pictureUrl!} />
                <MdOutlineKeyboardArrowDown size={25} className="dropdown__dropdown-icon" data-testid="key-down-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-structural-CardBG p-0 border border-structural-stroke-300 overflow-hidden">
                <Dropdown.Item className="text-text-low-emphasis mt-1 mb-1" disabled>
                    <Typography variant="overline">{email}</Typography>
                </Dropdown.Item>
                <Dropdown.Divider className="bg-structural-stroke-500 m-0" />
                {tenants && tenants.length > 1 && (
                    <Dropdown.Item className="text-text-high-emphasis mt-1 mb-1">
                        <div className="text-text-medium-emphasis w-100 ps-0 d-flex align-items-center gap-2 border-0">
                            <FaKey />
                            <Link to="/auth/tenants" className="sideNav__link">
                                <Typography variant="body5">Change Tenant</Typography>
                            </Link>
                        </div>
                    </Dropdown.Item>
                )}
                <Dropdown.Item className="text-text-high-emphasis mt-1 mb-1" onClick={() => onClickSignOutHandler()}>
                    <div className="text-text-medium-emphasis w-100 ps-0 d-flex align-items-center gap-2 border-0">
                        <ReactSVG src={SignOutIcon} />
                        <div className="sideNav__link">
                            <Typography variant="body5">Sign Out</Typography>
                        </div>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const SideNav = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const navItems =
        auth.user.role === AuthRole.ADMIN || auth.user.role === AuthRole.OWNER ? adminNavItems : viewerNavItems;
    const dispatch = useDispatch<Dispatch>();
    const navigate = useNavigate();
    const { cache } = useSWRConfig();

    const onClickHandler = () => {
        clearSWRCache(cache);
        dispatch.auth?.resetAuth();
        dispatch.ssoModel.setError(null);
        navigate(SIGN_IN);
    };

    return (
        <div className="sideNav">
            <Navbar bg="dark" variant="dark" className="sideNav__navbar">
                <Container className="sideNav__container">
                    <Navbar.Brand className="sideNav__brand">
                        <ReactSVG src={AmplifierLogoBlack} className="brand-icon" />
                    </Navbar.Brand>
                    <Nav className="sideNav__nav align-items-center">
                        {navItems.map(({ Icon, link, text }, index) => {
                            return (
                                <Nav.Link as={NavLink} to={link} key={index.toString()} className="sideNav__link">
                                    {typeof Icon === 'string' ? (
                                        <ReactSVG src={Icon} className="sideNav__svg-icon" />
                                    ) : (
                                        <div className="sideNav__react-icon">{Icon}</div>
                                    )}
                                    <Typography variant="caption3">{text.toUpperCase()}</Typography>
                                </Nav.Link>
                            );
                        })}
                    </Nav>
                    <ProfileComponent onClickSignOutHandler={onClickHandler} />
                </Container>
            </Navbar>
        </div>
    );
};

export default SideNav;
