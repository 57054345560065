import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Chip } from 'Components/atoms';
import { Typography } from 'Components/atoms';
import { DEFAULT_WORKFLOW_ROUTE } from 'Core-utils/routes';
import { FLOW_HEADER_TEXTS } from 'Core-utils/constants/constants';
import { FlowSpecDto, FlowSpecStatusKind } from '@ampsec/platform-client';
import { TbDotsVertical } from '@react-icons/all-files/tb/TbDotsVertical';
import { Dropdown } from 'react-bootstrap';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { startCase } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import { EngagementsModal } from 'Components/organisms/ActiveEngagementsModal';
import { ComponentProps } from 'react';

export type EngagementModalVariant = ComponentProps<typeof EngagementsModal>['variant'];

interface EngagementFlowTableProps {
    onUpdateStatus: (id: string, value: FlowSpecStatusKind) => void;
    handleDropdownItemAction: (eventKey: string, id: string) => void;
}

const MORE_OPTIONS = [
    {
        id: '1',
        value: 'DELETE',
    },
    {
        id: '2',
        value: 'DUPLICATE',
    },
];

const ClickableEngagement = ({ count, onClick }: { count?: number; onClick?: () => void }) => (
    <div
        role="button"
        className="engagement-count-clickable d-flex align-items-center justify-content-center rounded-circle text-decoration-underline"
        onClick={onClick}
    >
        <Typography variant="body5" color="text-high-emphasis">
            {count}
        </Typography>
    </div>
);

export const ENGAGEMENT_FLOW_TABLE_HEADER = (
    onUpdateStatus: EngagementFlowTableProps['onUpdateStatus'],
    handleDropdownItemAction: EngagementFlowTableProps['handleDropdownItemAction'],
    handleShowActiveEngagementModal: (flowSpecId: string, modalVariant: EngagementModalVariant) => void,
): TableColumn<FlowSpecDto>[] => {
    return [
        {
            id: 'name',
            width: '25rem',
            name: (
                <Typography variant="body2" color="text-black">
                    {FLOW_HEADER_TEXTS[0]}
                </Typography>
            ),
            sortable: true,

            style: {
                cursor: 'pointer',
            },
            cell: (row: FlowSpecDto) => {
                return (
                    <Link
                        to={`${DEFAULT_WORKFLOW_ROUTE}/create-engagement/${row.id}`}
                        className="engagement-clickable text-decoration-none text-truncate"
                    >
                        <Typography variant="body5" color="text-high-emphasis">
                            {row.name}
                        </Typography>
                    </Link>
                );
            },
        },
        {
            id: 'filter',
            name: (
                <Typography variant="body2" color="text-black">
                    {FLOW_HEADER_TEXTS[1]}
                </Typography>
            ),
            sortable: false,
            cell: (row: FlowSpecDto) => {
                const cohorts = row.filter?.cohorts || [];

                return (
                    <Typography variant="body5" color="text-high-emphasis">
                        {cohorts.length === 0
                            ? 'ALL USERS'
                            : cohorts.map((item, index) => {
                                  const className = classNames({
                                      'text-decoration-line-through': !item?.inclusive,
                                  });
                                  return (
                                      <span key={index} className={className}>
                                          {startCase(item?.displayValue)}
                                          {index !== cohorts.length - 1 && ' , '}
                                      </span>
                                  );
                              })}
                    </Typography>
                );
            },
            width: '22rem',
        },
        {
            id: 'lastTriggered',
            name: (
                <Typography variant="body2" color="text-black">
                    {FLOW_HEADER_TEXTS[2]}
                </Typography>
            ),

            cell: (row: FlowSpecDto) => (
                <Typography variant="body5" color="text-high-emphasis">
                    {row.activity?.lastTriggered ?? 'N/A'}
                </Typography>
            ),
            width: '15rem',
        },
        {
            id: 'total',
            name: (
                <div className="w-100 text-center">
                    <Typography variant="body2" color="text-black">
                        {FLOW_HEADER_TEXTS[4]}
                    </Typography>
                </div>
            ),
            center: true,

            cell: (row: FlowSpecDto) => (
                <ClickableEngagement
                    count={row.activity?.totalCount}
                    onClick={() => handleShowActiveEngagementModal(row.id, 'all')}
                />
            ),
            width: '8rem',
        },
        // {
        //     id: 'activity.closedCount',
        //     name: (
        //         <div className="w-100 text-center">
        //             <Typography variant="body2" color="text-black">
        //                 {FLOW_HEADER_TEXTS[6]}
        //             </Typography>
        //         </div>
        //     ),
        //     center: true,

        //     cell: (row: FlowSpecDto) => {
        //         return (
        //             <Typography variant="body5" color="text-high-emphasis">
        //                 {row.activity?.closedCount}
        //             </Typography>
        //         );
        //     },
        //     width: '8rem',
        // },
        {
            id: 'activity.activeCount',
            name: (
                <div className="w-100 text-center">
                    <Typography variant="body2" color="text-black">
                        {FLOW_HEADER_TEXTS[3]}
                    </Typography>
                </div>
            ),
            center: true,

            cell: (row: FlowSpecDto) => (
                <ClickableEngagement
                    count={row.activity?.activeCount}
                    onClick={() => handleShowActiveEngagementModal(row.id, 'active')}
                />
            ),
            width: '8rem',
        },
        // {
        //     id: 'activity.healingRate',
        //     name: (
        //         <div className="w-100 text-center">
        //             <Typography variant="body2" color="text-black">
        //                 {FLOW_HEADER_TEXTS[7]}
        //             </Typography>
        //         </div>
        //     ),
        //     center: true,

        //     cell: (row: FlowSpecDto) => {
        //         const healingRate = Math.round(Number(row.activity?.healingRate));
        //         return (
        //             <Typography variant="body5" color="text-high-emphasis">
        //                 {healingRate + '%'}
        //             </Typography>
        //         );
        //     },
        //     width: '8rem',
        // },
        {
            id: 'activity.avgHealingTime',
            name: (
                <div className="w-100 text-center">
                    <Typography variant="body2" color="text-black">
                        {FLOW_HEADER_TEXTS[8]}
                    </Typography>
                </div>
            ),
            center: true,

            cell: (row: FlowSpecDto) => {
                const avgHealingTime =
                    row.activity?.avgHealingTime === 0
                        ? '-'
                        : moment.duration(row.activity?.avgHealingTime.toString()).humanize();
                return (
                    <Typography variant="body5" color="text-high-emphasis">
                        {avgHealingTime}
                    </Typography>
                );
            },
            width: '8rem',
        },
        {
            id: 'status',
            name: (
                <Typography variant="body2" color="text-black">
                    {FLOW_HEADER_TEXTS[5]}
                </Typography>
            ),
            sortable: true,
            cell: (row: FlowSpecDto) => {
                const menuItems = Object.values(FlowSpecStatusKind)
                    .filter((item) => item !== FlowSpecStatusKind.ARCHIVE && item !== FlowSpecStatusKind.ERROR)
                    .map((value) => ({
                        id: value,
                        value: value,
                    }));
                return (
                    <>
                        <Dropdown>
                            <Dropdown.Toggle className="border-0 text-text-low-emphasis d-flex gap-2 justify-content-center align-items-center">
                                <Chip value={row.status} variant="status" />
                                <RiArrowDownSLine />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="p-0 w-100 gap-2 bg-body-color border-text-medium-emphasis custom-scrollbar dropdown__custom-menu">
                                {menuItems.map((item) => (
                                    <Dropdown.Item
                                        key={item.id}
                                        eventKey={item.value}
                                        className="dropdown__menu-item d-flex align-items-center gap-2"
                                        onClick={() => onUpdateStatus(row.id, item.value)}
                                    >
                                        <Typography variant={'body5'} style={{ textTransform: 'uppercase' }}>
                                            {item.value}
                                        </Typography>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                );
            },
        },
        {
            id: 'threeDots',
            cell: (row: FlowSpecDto) => {
                return (
                    <>
                        <Dropdown>
                            <Dropdown.Toggle className="border-0 text-text-low-emphasis d-flex">
                                <TbDotsVertical />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="p-0 w-100 gap-2 bg-body-color border-text-medium-emphasis custom-scrollbar dropdown__custom-menu">
                                {MORE_OPTIONS.map((item) => (
                                    <Dropdown.Item
                                        key={item.id}
                                        eventKey={item.value}
                                        className="dropdown__menu-item d-flex align-items-center gap-2"
                                        onClick={() => handleDropdownItemAction(item.value, row.id)}
                                    >
                                        <Typography variant={'body5'} style={{ textTransform: 'uppercase' }}>
                                            {item.value}
                                        </Typography>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                );
            },
        },
    ];
};
